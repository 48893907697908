var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateAssetStyled', [_c('InputField', {
    staticClass: "name",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.name.$model,
            expression: "$v.name.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Default Name"
          },
          domProps: {
            "value": _vm.$v.name.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.name, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Name ")];
      },
      proxy: true
    }, _vm.$v.name.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.name.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.name.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must have at least " + _vm._s(_vm.$v.name.$params.minLength.min) + " letters.")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "serialNumber",
    attrs: {
      "labelWidth": 8
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.serialNumber.$model,
            expression: "$v.serialNumber.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Serial Number"
          },
          domProps: {
            "value": _vm.$v.serialNumber.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.serialNumber, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Serial Number ")];
      },
      proxy: true
    }, _vm.$v.serialNumber.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.serialNumber.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.serialNumber.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" Field must have at least " + _vm._s(_vm.$v.serialNumber.$params.minLength.min) + " letters. ")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('assetType', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetTypes,
      "value": _vm.selectedAssetType,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setAssetType
    }
  }), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'CreateAssetTypeOverlay');
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('assetModel', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetModels,
      "value": _vm.selectedAssetModel,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setAssetModel
    }
  }), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'CreateModelTypeOverlay');
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('oem', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedOrganization,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setOrganization
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }