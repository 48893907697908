<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateAssetStyled>
      <InputField :labelWidth="6" class="name">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.name.$model" placeholder="Default Name" />
        </template>
        <template v-slot:label> Name </template>
        <template v-slot:errors v-if="$v.name.$dirty">
          <div class="error" v-if="!$v.name.required">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <InputField :labelWidth="8" class="serialNumber">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.serialNumber.$model" placeholder="Serial Number" />
        </template>
        <template v-slot:label> Serial Number </template>
        <template v-slot:errors v-if="$v.serialNumber.$dirty">
          <div class="error" v-if="!$v.serialNumber.required">Field is required</div>
          <div class="error" v-if="!$v.serialNumber.minLength">
            Field must have at least {{ $v.serialNumber.$params.minLength.min }} letters.
          </div>
        </template>
      </InputField>
      <div class="type">
        <div class="type-label">
          {{ $tc('assetType', 1) }}
        </div>
        <Multiselect
          :options="assetTypes"
          :value="selectedAssetType"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setAssetType"
        />
        <div class="add-icon">
          <PlusCircleIcon @click="$root.$emit('activateOverlay', 'CreateAssetTypeOverlay')" />
        </div>
      </div>
      <div class="type">
        <div class="type-label">
          {{ $tc('assetModel', 1) }}
        </div>
        <Multiselect
          :options="assetModels"
          :value="selectedAssetModel"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setAssetModel"
        />
        <div class="add-icon">
          <PlusCircleIcon @click="$root.$emit('activateOverlay', 'CreateModelTypeOverlay')" />
        </div>
      </div>
      <div class="type">
        <div class="type-label">
          {{ $tc('oem', 1) }}
        </div>
        <Multiselect
          :options="organizations"
          :value="selectedOrganization"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setOrganization"
        />
      </div>
    </CreateAssetStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'
import chroma from 'chroma-js'
import { FlashMessages } from '@common/singletons'

import { PlusCircleIcon } from 'vue-feather-icons'

import Multiselect from 'vue-multiselect'

import { flexCenter } from '@styles/mixins'

import { required, minLength } from 'vuelidate/lib/validators'

import CREATE_ASSET_MUTATION from '#/graphql/operations/asset/createAsset.gql'
import ASSET_TYPES_QUERY from '#/graphql/assetTypes/assetTypes.gql'
import ASSET_MODELS_QUERY from '#/graphql/assetModels/assetModels.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'

const CreateAssetStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  .type {
    position: relative;
    max-width: 22rem;
    display: flex;
    align-items: center;
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    backdrop-filter: blur(7px);
    padding: 0.25rem;
    .type-label {
      ${flexCenter}
      font-size: .8rem;
      padding: 0.25rem;
      color: ${p => p.theme.colors.archonBlue};
      height: 1.75rem;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    }
    .basic-select {
      max-width: 20rem;
      margin-left: 1rem;
    }
  }
  .add-icon {
    ${flexCenter}
    margin-left: 2rem;
    width: 80px;
    height: 40px;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    &:hover {
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    }
  }
`

export default {
  components: {
    CreateAssetStyled,
    ModelCreater,
    Multiselect,
    InputField,
    PlusCircleIcon,
  },
  data() {
    return {
      name: '',
      password: '',
      errors: [],
      organizations: [],
      selectedOrganization: null,
      assetTypes: [],
      assetModels: [],
      selectedAssetType: null,
      selectedAssetModel: null,
      isLoading: false,
      isCreating: false,
      serialNumber: '',
    }
  },
  computed: {
    title() {
      return `Create Asset`
    },
    canCreate() {
      return (
        !this.$v.$invalid &&
        !!get(this.selectedAssetType, 'id') &&
        !!get(this.selectedOrganization, 'id') &&
        !!get(this.selectedAssetModel, 'id') &&
        !this.isCreating
      )
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    serialNumber: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    setOrganization(organization) {
      this.selectedOrganization = organization
    },
    setAssetType(type) {
      this.selectedAssetType = type
    },
    setAssetModel(model) {
      this.selectedAssetModel = model
    },
    async create() {
      this.isCreating = true
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ASSET_MUTATION,
          variables: {
            data: {
              name: this.name,
              typeId: this.selectedAssetType.id,
              manufacturerCuid: this.selectedOrganization.id,
              modelId: this.selectedAssetModel.id,
              serialNumber: this.serialNumber,
            },
          },
        })
        const assetId = res?.data?.createMachine?.i
        if (assetId) {
          this.$emit('reload')
          this.$router.push({
            name: 'asset',
            params: {
              id: assetId,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
        FlashMessages.$emit('error', err)
      }
      this.isCreating = false
    },
  },
  apollo: {
    assetTypes: {
      query: ASSET_TYPES_QUERY,
    },
    assetModels: {
      query: ASSET_MODELS_QUERY,
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
    },
  },
}
</script>
